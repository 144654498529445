import React from "react";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { getSupplierTagDropdown } from "../../../../selectors/dropdowns";
import { Select } from "../../../helpers";
import { FilterMainComponentProps } from "../Filter";

const SupplierTagSelect = ({
  values,
  onChange,
  placeholder = "All",
}: FilterMainComponentProps) => {
  const tagOptions = createOptions(
    useSelector(getSupplierTagDropdown),
    "tag_id",
    "label",
    { addAll: true },
  );

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      // @ts-ignore
      options={tagOptions}
      placeholder={placeholder}
    />
  );
};

export default SupplierTagSelect;
